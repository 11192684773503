import React, { useEffect } from "react";
import SEO from "@src/components/SEO";
import { logEvent } from "@src/utils/logClient";
import Gameplay2025 from "@src/assets/images/game2025.jpg";

const GamePlayPage = () => {
  useEffect(() => {
    logEvent({
      eventName: "web.external.gametime2025.page-load",
      eventValue: window.location.href,
    });
  }, []);

  return (
    <>
      <SEO title="Who will win?!" description="Vote for your favorite!" image={Gameplay2025} />
      <iframe
        src="https://superbowl-263d7.web.app"
        width="100%"
        height="800px"
        style={{ border: "none", display: "block" }}
        title="Who will win?!"
      ></iframe>
    </>
  );
};

export default GamePlayPage;
